Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.product_cataloge = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.carouselHeadingText = "NEW - SEASON STYLE"
exports.carouselSubHeadingText = "Discover the key and styling tricks to elevate your look now"
exports.recommendedHeadingText = "Recommendations For You"
exports.stylistHeadingText = "Stylist Services"
exports.trendingHeadingText = "Exclusive New Trending Collection"
exports.newLauchHeadingText = "New In"
exports.getLandingCarouselApi = "bx_block_content_management/landing_page_contents";
exports.getStylistServiceApi = "portfolio/portfolios_with_one_image?per_page=4&page=1";
exports.getLandingPageTitleContentApi = "bx_block_content_management/landing_page_datas";
exports.getLandingPageTrendingPageApi = "bx_block_catalogue/catalogues/landing_page?page=1&per_page=4&sort=trending";
exports.getLandingPageTrendingListPageApi = "bx_block_catalogue/catalogues/landing_page?sort=trending";
exports.getLandingPageNewLauncheApi = "bx_block_catalogue/catalogues/landing_page?sort=new_launches";
exports.getLandingPageRecommendationsApi = "bx_block_catalogue/catalogues/landing_page_search?sort=recommendations";
exports.postFavouriteApi = "bx_block_favourites/favourites";
exports.deleteFavouriteApi = "/bx_block_favourites/favourites/destroy_by_favouritable";
exports.getLandingPageTrendingApi = "bx_block_catalogue/catalogues/landing_page_search?page=1&per_page=4&sort=trending";
exports.getLandingPageTrendAllToken = "bx_block_catalogue/catalogues/landing_page_search";
exports.getLandingPageNewLaunchApi = "bx_block_catalogue/catalogues/landing_page_search?sort=new_launches";
exports.getActiveCartView = "bx_block_order_management/orders/get_active_cart_view?unique_token";
exports.getUnique_token = "bx_block_order_management/orders/get_active_cart?unique_token";
exports.headerTextEndPoint = "bx_block_content_management/landing_page_contents/website_header"

exports.offerPercentage = "% Off";

exports.btnExampleTitle = "CLICK ME";
exports.addFavTitle = "item added to wishlist";
exports.removeFavTitle = "The item has been removed from wishlist";
exports.landLang = {
  "Hello": "Hello"
}
// Customizable Area End