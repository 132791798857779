import React from "react";

// Customizable Area Start
import { Typography, Box, Grid, Card, TextField, styled, InputAdornment, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Header from "../../../../components/src/Header";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { Vector } from "../../../customisableuserprofiles2/src/assets";
// Customizable Area End

import BuyerStoreProductController, {
  Props,
  configJSON
} from "./BuyerStoreProductController";
import Footer from "../../../../components/src/Footer";
import { noImage } from "../../../landingpage/src/assets";
import { search } from "../../../catalogue/src/assets";
import StylishBuyerHeader from "../../../../components/src/StylishBuyerHeader";


export default class BuyerStoreProduct extends BuyerStoreProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {!this.state.buyerStylish ? <Header navigation={this.props.navigation} /> : <StylishBuyerHeader navigation={this.props.navigation} />}

        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>
            {this.state.alertMsg}</Alert>
        </Snackbar>
        <MainWrapper>
          <div
            style={{ ...webStyles.innerContainer }}>
            <Typography style={webStyles.innerContainer1} data-test-id="navigateToHome" onClick={() => this.navigateToHome("LandingPage")}>
              Home
            </Typography>
            <img src={Vector} />
            <Typography style={webStyles.innerContainer1} data-test-id={'navigateToCatgory'} onClick={() => this.navigateToHome("BuyerStore")}>
              Stores
            </Typography>
            <img src={Vector} />
            <Typography style={webStyles.innerContainer1}  >
              {this.state.storeList?.attributes?.store_name}
            </Typography>
          </div>
          <Box className="primaryContainer">
            <Box className="imageContainer">
              <img src={this.state.storeList?.attributes?.image} className="img" />
            </Box>

            <Box className="secondaryContainer">
              <Box style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <Typography className="storeName">{this.state.storeList?.attributes?.store_name || ""}</Typography>
                <Box style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                  <Typography className="storeDescriptionTitle">Store Description</Typography>
                  <Typography className="storeDescription">{this.state.storeList?.attributes?.description}</Typography>
                  <Box className="lastContainer">
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {window.innerWidth > 768 &&
            <>
              <Box id="accordion-box">
                <Accordion id="accordion" style={webStyles.allSelectColor}>
                  <Box id="accordion-summary-box">
                    <AccordionSummary
                      id="accordion-summary"
                      style={{ height: "96px", padding: "0px" }}
                      expandIcon={<ExpandMoreRoundedIcon id="expand-icon" style={webStyles.expandMoreIcon} />}
                    >
                      <Typography id="accordion-summary-title" style={webStyles.allFilterFont}>More Details</Typography>
                    </AccordionSummary>
                  </Box>
                  <AccordionDetails id="accordion-details">
                    <Box
                      id="accordion-details-container"
                      style={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" }}
                    >
                      <Box id="address-box" style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        <Typography id="address-label" style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 700, color: "#375280" }}>Address</Typography>
                        <Typography
                          id="address-value"
                          style={{ fontFamily: "Lato", fontSize: "18px", fontWeight: 400, color: "#375280" }}
                        >
                          {this.state.storeList?.attributes?.block} {this.state.storeList?.attributes?.floor} {this.state.storeList?.attributes?.address}, {this.state.storeList?.attributes?.city}
                        </Typography>
                      </Box>
                      <Box id="contact-email-container" style={{ display: "flex", gap: "20px" }}>
                        <Box
                          id="contact-number-box"
                          style={{ display: "flex", flexDirection: "column", gap: "8px", width: "49%" }}
                        >
                          <Typography id="contact-number-label" style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 700, color: "#375280" }}>Contact Number</Typography>
                          <Typography
                            id="contact-number-value"
                            style={{ fontFamily: "Lato", fontSize: "18px", fontWeight: 400, color: "#375280" }}
                          >
                            {this.state.storeList?.attributes?.contact_number?.country_code} {this.state.storeList?.attributes?.contact_number?.phone_number}
                          </Typography>
                        </Box>

                        <Box
                          id="email-box"
                          style={{ display: "flex", flexDirection: "column", gap: "8px", width: "49%" }}
                        >
                          <Typography id="email-label" style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 700, color: "#375280" }}>Email</Typography>
                          <Typography
                            id="email-value"
                            style={{ fontFamily: "Lato", fontSize: "18px", fontWeight: 400, color: "#375280" }}
                          >
                            {this.state.storeList?.attributes?.email}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        id="store-hours-box"
                        style={{ display: "flex", flexDirection: "column", gap: "8px", width: "49%" }}
                      >
                        <Typography id="store-hours-label" style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 700, color: "#375280" }}>Store Hours</Typography>
                        <Box id="store-hours-mon-to-fri" style={{ display: "flex", gap: "100px" }}>
                          <Typography
                            id="mon-to-fri-label"
                            style={webStyles.monToSatTxt as React.CSSProperties}
                          >
                            Monday to Friday
                          </Typography>
                          <Typography
                            id="mon-to-fri-value"
                            style={webStyles.monToSatTxtData as React.CSSProperties}
                          >
                            {`${this.state.mondayWeek.start} AM to ${this.state.mondayWeek.end} PM`}
                          </Typography>
                        </Box>

                        <Box id="store-hours-sat-to-sun" style={{ display: "flex", gap: "86px" }}>
                          <Typography
                            id="sat-to-sun-label"
                            style={webStyles.monToSatTxt as React.CSSProperties}
                          >
                            Saturday to Sunday
                          </Typography>
                          <Typography
                            id="sat-to-sun-value"
                            style={webStyles.monToSatTxtData as React.CSSProperties}
                          >
                            {`${this.state.satWeek.start} AM to ${this.state.satWeek.end} PM`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>

            </>
          }
          {window.innerWidth < 768 &&
            <>
              <Box>
                <Accordion style={webStyles.allSelectColor}>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <AccordionSummary style={{ height: "96px", padding: "0px" }} expandIcon={<ExpandMoreRoundedIcon style={webStyles.expandMoreIcon} />}>
                      <Typography style={webStyles.allFilterFont}>More Details</Typography>
                    </AccordionSummary>
                  </Box>
                  <AccordionDetails>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "10px", width: "80%", alignItems: "center", justifyContent: "center" }}>
                      <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Typography style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 700, color: "#375280" }}>Address</Typography>
                        <Typography style={{ fontFamily: "Lato", fontSize: "18px", fontWeight: 400, color: "#375280" }}>{this.state.storeList?.attributes?.block} {this.state.storeList?.attributes?.floor} {this.state.storeList?.attributes?.address}, {this.state.storeList?.attributes?.city}</Typography>
                      </Box>
                      <Box style={{ display: "flex", flexDirection: "column", gap: "8px", width: "49%" }}>
                        <Typography style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 700, color: "#375280" }}>
                          Contact Number
                        </Typography>
                        <Typography style={{ fontFamily: "Lato", fontSize: "18px", fontWeight: 400, color: "#375280" }}>
                          {this.state.storeList?.attributes?.contact_number
                            ? `${this.state.storeList.attributes.contact_number.country_code || ""} ${this.state.storeList.attributes.contact_number.phone_number || ""}`
                            : "Not Available"}
                        </Typography>
                      </Box>

                      <Box style={{ display: "flex", flexDirection: "column", gap: "5px", width: "100%" }}>
                        <Typography style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 700, color: "#375280" }}>Store Hours</Typography>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
                          <Typography style={webStyles.monToSatTxt as React.CSSProperties}>Monday to Friday</Typography>
                          <Typography style={webStyles.monToSatTxtData as React.CSSProperties}>{`${this.state.mondayWeek.start} AM to ${this.state.mondayWeek.end} PM`}</Typography>
                        </Box>

                        <Box style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
                          <Typography style={webStyles.monToSatTxt as React.CSSProperties}>Saturday to Sunday</Typography>
                          <Typography style={webStyles.monToSatTxtData as React.CSSProperties}>{`${this.state.satWeek.start} AM to ${this.state.satWeek.end} PM`}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </>
          }

          <Box style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
            <Box className="thirdContainer">
              <Box className="products">Products by <span style={{ textTransform: "capitalize" }}>
                {this.state.storeList?.attributes?.store_name}</span>
              </Box>
              <CustomInput id="outlined-basic" placeholder="Search" variant="standard"
                data-test-id="handleSearch"
                onChange={(event) => this.handleChangeSearch(event.target.value)}
                style={{ display: "flex", width: "249px", height: "48px" }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={search} alt="" />
                    </InputAdornment>
                  ),
                }} /> &nbsp;
            </Box>
            <Grid container
              spacing={0} style={webStyles.heraderText}>
              <Grid container spacing={2} style={{ display: "flex", marginLeft: "10px" }}>
                {this.state.variantsData?.length > 0 &&
                  this.state.variantsData?.map((cardDetail: any, index: number) => (
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12} style={webStyles.trendingGrid} key={cardDetail.id}>
                      <Card data-test-id="trendingCart" style={webStyles.cardContainer}>
                        <Box style={{ position: 'relative' }}>
                          <img
                            src={cardDetail?.attributes?.primary_image ? cardDetail?.attributes?.primary_image : noImage}
                            alt={`Image ${1}`}
                            style={webStyles.imgTag}
                            onClick={this.navigateToDeatils.bind(this, { id: Number(cardDetail?.id), screenName: "Productdescription3" })}
                            data-test-id={`navigateToDetail-${index}`}
                          />
                          <Box style={webStyles.productIcon1}>
                            <Box style={{ ...webStyles.productDiscount1, backgroundColor: cardDetail.attributes.primary_discounted_percentage > 0 ? "#FFFFFF" : "transparent" }}>
                              {cardDetail.attributes.primary_discounted_percentage > 0 &&
                                `${cardDetail.attributes.primary_discounted_percentage}% Off`}
                            </Box>
                            <Box style={webStyles.productFavorite1} data-test-id={`handleLikeClick-${index}`}
                              onClick={() => this.handleFavouriteApi(cardDetail?.id, cardDetail?.attributes?.is_wishlist)}
                            >
                              {cardDetail.attributes?.is_wishlist ? (
                                <FavoriteIcon width="18px" height="18px" style={{ fill: 'blue' }} />
                              ) : (<FavoriteBorderIcon color="secondary" style={{ fill: 'blue' }} />)
                              }
                            </Box>

                          </Box>
                        </Box>
                        <Box style={{ padding: '24px' }}>
                          <Box style={webStyles.productData1}>
                            <Box style={webStyles.productValue1}>
                              <Typography style={webStyles.productName1} >{cardDetail?.attributes?.name}</Typography>
                              <Typography style={webStyles.productDescription1} >{cardDetail?.attributes?.description}</Typography>
                            </Box>
                            <Box style={{ ...webStyles.acountMrp1, textAlign: 'center', width: "85px" }}>{cardDetail.attributes.primary_discounted_percentage > 0 ? (
                              <>
                                <Typography id="actual-buyer-store-prod-price">
                                  <del>{`${this.state.currencySign} ${cardDetail.attributes.primary_main_price}`}</del>
                                </Typography>
                                <Typography id="buyer-store-prod-list-price" style={webStyles.actualPrice}>{`${this.state.currencySign} ${cardDetail.attributes.primary_price}`}</Typography>
                              </>
                            ) : (
                              <Typography id="primary-buyer-store-prod-list-price" style={webStyles.actualPrice}>{`${this.state.currencySign} ${cardDetail.attributes.primary_main_price}`}</Typography>
                            )}</Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  ))}

                {this.state.variantsData?.length === 0 && (
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Lato", fontSize: "20px", fontWeight: 500, marginBottom: "40px", width: "100%", color: "#375280" }}>No Data Found</Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </MainWrapper>
        <Footer />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  marginLeft: "40px",
  marginTop: "27px", width: "95%",
  display: "flex", gap: "48px", flexDirection: "column", justifyContent: "center",
  "& .lastContainer": { display: "flex", flexDirection: "column", gap: 20 },
  "& .imageContainer": { height: "426px", width: "40%", minWidth: "250px" },
  "& .primaryContainer": {
    display: "flex",
    gap: "85px",
    flexDirection: "row",
  },
  "& .storeDescription": {
    fontSize: "18px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#375280",
    height: "354px",
    overflowY: "auto",
  },
  "& .img": { height: "100%", width: "100%", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" },
  "& .secondaryContainer": { width: "60%", display: "flex", gap: 45, flexDirection: "column" },
  "& .storeName": { fontSize: "30px", fontFamily: "Lato", fontWeight: 700, color: "#375280", textTransform: "capitalize" },
  "& .storeDescriptionTitle": { fontSize: "16px", fontFamily: "Lato", fontWeight: 900, color: "#375280" },
  "& .divider": { color: "#BEBCBD", width: "100%" },
  "& .storeInformation": { fontFamily: "lato", fontSize: "24px", color: "#375280", fontWeight: 700 },
  "& .weekDayContainer": { display: "flex", flexWrap: "wrap" },
  "& .weekDay": { width: "50%", color: "#375280", fontFamily: "Lato", fontWeight: 500, fontSize: "20px", textTransform: "capitalize" },
  "& .timings": { width: "45%", color: "#375280", fontFamily: "Lato", fontWeight: 500, fontSize: "20px" },
  "& .thirdContainer": { display: "flex", width: "100%", justifyContent: "space-between", },
  "& .products": { color: "#375280", fontFamily: "Lato", fontSize: "32px", fontWeight: 700, width: "90%", },
  "@media (max-width: 768px)": {
    marginLeft: "0px",
    alignItems: "center",
    "& .primaryContainer": {
      flexDirection: "column",
    },
    "& .storeDescription": {
      height: "100px",
    },
    "& .imageContainer": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "10px"
    },
    "& .secondaryContainer": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "& .thirdContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .products": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }
  },
})
const webStyles = {
  allSelectColor: { backgroundColor: '#ffffff', borderRadius: '0px', boxShadow: "none", borderBottom: "1px solid #D5D5D5" },
  innerContainer1: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    cursor: "pointer",
    fontWeight: 400,
  },

  monToSatTxt: {
    fontFamily: "Lato", fontSize: "18px", fontWeight: 400, color: "#375280", whiteSpace: "nowrap"
  },
  monToSatTxtData: {
    fontFamily: "Lato", fontSize: "18px", fontWeight: 700, color: "#375280", whiteSpace: "nowrap"
  },
  allFilterFont: { color: '#375280', cursor: "pointer", fontSize: '24px', fontWeight: 700, fontFamily: "Lato" },
  expandMoreIcon: { color: "#807D7E", fontSize: '30px' },
  innerContainer: {
    color: "#475569",
    fontWeight: 400,
    lineHeight: "22px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  heraderText: { background: "#FFF", justifyContent: "center", alignItems: "center" },
  productIcon1: { top: '20px', left: '20px', display: "flex", justifyContent: 'space-between', right: '0', position: 'absolute' as 'absolute' },
  productFavorite1: { color: "#375280", marginRight: '30px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  acountMrp1: { fontFamily: "Lato", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: '#FFFFFF', border: "1px solid #D5D5D5", padding: '10px 10.5px', borderRadius: '8px', width: '88px', flexDirection: "column-reverse" as "column-reverse" },
  productDiscount1: { color: "#375280", fontSize: '12px', padding: '2px 0px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
  actualPrice: { fontFamily: "Lato", color: '#375280', fontSize: '18px', fontWeight: 700 },
  trendingGrid: { display: 'flex', marginBottom: '20px' },
  productValue1: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto', whiteSpace: 'nowrap' as 'nowrap', },
  productDescription1: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, whiteSpace: 'nowrap' as 'nowrap', textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  cardContainer: { boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", width: "340px", height: "455px", backgroundColor: "#f2efef" },
  productData1: {
    width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden',
    textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle', whiteSpace: 'nowrap' as 'nowrap', flexDirection: 'row' as 'row'
  },
  productName1: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", whiteSpace: 'nowrap' as 'nowrap', overflow: "hidden", width: '90%', fontFamily: "Lato" },
  imgTag: {
    width: "340px",
    height: "370px",
    objectFit: "cover" as "cover",
    position: 'relative' as 'relative', display: 'block', margin: 'auto'
  },
};

const CustomInput = styled(TextField)({
  marginLeft: "20px",
  border: "1px solid #CBD5E1",
  borderRadius: "4px",
  width: "350px",
  minHeight: "40px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  display: "flex",
  paddingLeft: 11,
  justifyContent: "center",
  alignItems: "center",
  color: "#375280 !important",
  "& .MuiInput-root": {
    color: "#375280",
    display: "flex", alignItems: "center"
  },
  '& ::placeholder': {
    color: "#43556E;",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  }
});
// Customizable Area End

