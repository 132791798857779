import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
import moment from 'moment';
import { handleNotificationClick } from "../../../components/src/Chat/chat.web";

export interface INotifications {
  id: string;
  attributes: Attributes;
  type: string;
}
export interface Attributes {
  created_by?: null;
  id: number;
  headings: string;
  contents: string;
  read_at?: null;
  contents_arabic?: null;
  app_url?: null;
  is_read: boolean;
  action: string;
  data: Data;
  account: Account;
  created_at: string;
  updated_at: string;
}
export interface Data {
  action: string;
  order_id: number;
  account_id: number;
  order_number: number | string;
  catalogue_variant_id?: null;
  catalogue_id?: null;
  notification_name?: null;
  store_id?: null;
}
export interface Account {
  last_name: string;
  full_phone_number: string;
  first_name: string;
  country_code: number;
  id: number;
  email: string;
  activated: boolean;
  unique_auth_id: string;
  device_id?: null;
  password_digest: string;
  created_at: string;
  phone_number: number;
  updated_at: string;
  platform?: null;
  user_name?: null;
  user_type?: null;
  app_language_id?: null;
  is_blacklisted: boolean;
  last_visit_at?: null;
  suspend_until?: null;
  status: string;
  gender?: null;
  date_of_birth?: null;
  age?: null;
  stripe_id?: null;
  stripe_subscription_id?: null;
  stripe_subscription_date?: null;
  role: string;
  approve_status: string;
  is_verified?: null;
  full_name: string;
  share_token?: null;
  seller_status: string;
  wallet_amount: string;
  notification: Notification;
  withdrawable_amount: string;
  customer_id: string;
  longitude?: null;
  latitude?: null;
  driver_status: string;
  language: string;
  currency: string;
  stylist_redirect_flag?: null;
  driver_redirect_flag?: null;
  filter_range: string;
  selected_month: string;
  deleted_at?: null;
  secondary_email?: null;
}
export interface Notification {
  email_notification: PushOrEmailNoti;
  push_notification: PushOrEmailNoti;
}
export interface PushOrEmailNoti {
  order_confirmations: boolean;
  order_invoices: boolean;
  delivery_confirmation: boolean;
  marketing_emails: boolean;
  reviews_and_feedback_requests: boolean;
  product_stock_updates: boolean;
  refund_or_payment_complete: boolean;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  isDrawerOpen: boolean;
  token: any;
  value:number;
  userType: string;
  fullName: string;
  notifications: INotifications[];
  exploreBuyer: boolean;
  isLoad: boolean,
  isNotificationAlert: boolean;
  errorMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  getNotificationsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      isDrawerOpen: false,
      token: "",
      value:0,
      userType: "",
      fullName: "", 
      notifications: [], 
      exploreBuyer: false,
      isLoad: false,
      isNotificationAlert: false,
      errorMessage: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setState({ isLoad: true })
    this.getUserNotifications();
    const useRole = await getStorageData('userRole', true);
    const name = await getStorageData('user_data', true);
    const exploreBuyer = await getStorageData('exploreBuyer', true);
    if(exploreBuyer) this.setState({exploreBuyer});
    this.setState({
      userType: useRole?.userType,
      fullName: `${name?.first_name} ${name?.last_name}`

    });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!apiResponse || apiResponse.errors) {
      this.handleResError(apiResponse);
      return;
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      apiResponse &&
        this.setState({
          data: apiResponse.data,
        });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getUserNotifications();
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
      this.getNotifications();
    } else if (this.getNotificationsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.setState({isLoad: false})
      apiResponse && this.setState({ notifications: apiResponse.data });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleResError = (apiResponse:{errors?: {[errKey:string]: string}[];}) => {
    this.setState({isLoad: false})
    if(apiResponse?.errors) {
      const errorMessages = apiResponse.errors.map((error: { [key:string]: string}) => Object.values(error)[0]).join(", ");
      this.setState({
        isNotificationAlert: true,
        errorMessage: errorMessages,
      })
    }
  }

  onCloseAlert = () => {
    this.setState({
      isNotificationAlert: false,
      errorMessage: "",
    })
  }

  notificationNavigation = async (item: Attributes) => {
    const { id, is_read } = item
    if(is_read === false) {
      this.markAsRead(id)
    }
    handleNotificationClick( item, this.props.navigation.navigate)
  };

  handleNavigationHome = (redirect: string) => {
    const userNavMsgHome: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsgHome.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsgHome.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsgHome);
  }
  handleThreeDotsClick = () => {
    this.setState(prevState => ({
      isDrawerOpen: true
    }));
  }

  handleDrawerClose = () => {
    this.setState({
      isDrawerOpen: false
    });
  }

  handleNavigation(){
    this.props.navigation.navigate("Notificationsettings")
  }
  iconBellProps = {
    source: imgBell,
  };

  getNotifications() {
    if(!this.state.token){
      return false
    }
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  async markAsRead(id: number) {
    const token = await getStorageData("auth-token");
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/notifications/${id}?is_read=true`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  transNotifications = (transKey: string) => {
    return i18n.t( transKey, {ns: "notification"})
  }

  getUserNotifications = async () => {
    const token = await getStorageData("auth-token");
    const headers = { 
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getNotificationsCallId = requestMessage.messageId;    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_notifications/notifications`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  formatDate = (dateString: string) => {
    const date = moment(dateString);
    const isToday = moment().isSame(date, 'day');
    const formattedTime = date.format('hh:mm A'); 
    return isToday ? `${this.transNotifications("Today")} | ${formattedTime}` : `${date.format('DD/MM/YYYY')} | ${formattedTime}`;
  };

  stripHTML = (html: string) => {
    return html.replace(/<[^>]*>?/gm, '');
  };
  // Customizable Area End
}
