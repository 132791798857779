import React from "react";
import {
  Box, Button, Typography,
  // Customizable Area Start
  Grid, Dialog, DialogContent, DialogActions
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import { Vector, badge, congratulations, credited, debited, loyality, scroll } from "../src/assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import ReusableSnackbar from "../../../components/src/AlertContainer";
import Loader from "../../../components/src/LoaderContainer";
import CustomTooltip from "../../../components/src/Chat/CustomTooltip.web";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

import LoyaltysystemController, {
  Props,
  configJSON,
} from "./LoyaltysystemController";
import { close } from "../../photolibrary/src/assets";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
import BuyerDrawer from "../../../components/src/SellerStylish/BuyerDrawer";
import MoreVertIcon from "@material-ui/icons/MoreVert"

export default class Loyaltysystem extends LoyaltysystemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFilter = () => {
    return this.state.transactionsRecords.length === 0 ? (
      <></>
    ) : (
      <select
        style={{
          width: "58px",
          backgroundColor: "#FFFFFF",
          color: "#375280",
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 400,
          border: "1px solid #CCBEB1",
        }}
        value={"All"}
        data-test-id="monthlyChange"
      >
        <option value="All">All</option>
      </select>
    )
  }

  imgIcon = (type: string) => {
    return type === "credit" ? (
      <img
        src={credited}
        style={{ height: "100%", width: "100%" }}
      />
    ) : (
      <img
        src={debited}
        style={{ height: "100%", width: "100%" }}
      />
    )
  }

  renderText = (type: string, orderNumber: string) => {
    const prefix = type === "credit" ? "" : this.transLoyaltyLng("Redeemed, ");
    return `${prefix}${this.transLoyaltyLng("Order")} #${orderNumber}`;
  };

  renderDate = (type: string, creditedAt: string | null, debitedAt: string | null) => {
    return type === "credit" ? `${this.transLoyaltyLng("Credited on")} ${this.handleDate(creditedAt)}`: `${this.transLoyaltyLng("Debited on")} ${this.handleDate(debitedAt)}`
  }

  renderColor = (type: string) => {
    return type === "credit" ? "#059669" : "#DC2626";
  }

  renderTransactions = () => {
    return (
      <Box
        style={{
          height: window.innerWidth > 950 ? "380px" : "",
          maxHeight: 380,
          overflow: "auto",
          width: "96%",
          border: "1px solid #E2E8F0",
          padding: "20px",
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          gap: window.innerWidth > 950 ? 20 : 0,
          marginBottom: "20px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 20,
            backgroundColor: "#fff",
            alignItems: "center",
            position: "sticky",
            top: 0,
          }}
        >
          <Typography
            style={{
              fontFamily: "Lato",
              fontSize: "20px",
              fontWeight: 700,
              color: "#375280",
            }}
          >
            {this.transLoyaltyLng("Transactions")}
          </Typography>
          {this.renderFilter()}

        </Box>
        {this.state.isTransactionLoading ? (
          <Loader
            id="loyalty-main-loader"
            dataTestId="loyalty-main-loader-test"
          />
        ) : (
          <>
            {this.state.transactionsRecords.length === 0 ? (
              <NoTransactionTypography>
                {this.transLoyaltyLng("Loyalty Point Transactions Not Found")}
              </NoTransactionTypography>
            ) : (
              <>
                {this.state.transactionsRecords.map((element) => (
                  <Box style={{ display: "flex", gap: "20px", width: "100%" }}>
                    <Box style={{ height: "29.79px", width: "29.79px" }}>
                      {this.imgIcon(element.transaction_type)}
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box>
                        <Typography
                          style={{
                            fontFamily: "Lato",
                            fontSize: "18px",
                            fontWeight: 500,
                            color: "#375280",
                          }}
                        >
                          {this.renderText(element.transaction_type, element.order_number)}
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Lato",
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#94A3B8",
                          }}
                        >
                          {this.renderDate(element.transaction_type, element.credited_on, element.debited_on)}
                        </Typography>
                      </Box>
                      <Typography
                        style={{
                          fontFamily: "Lato",
                          fontSize: "16px",
                          fontWeight: 700,
                          color: this.renderColor(element.transaction_type),
                        }}
                      >
                        {element.point}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </>
        )}
      </Box>
    );
  };

  renderRedeemPoints = () => {
    return (
      <Box
        style={{
          width: "100%",
          height: "380px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          overflowY: "auto",
        }}
      >
        {this.state.redeemPoints.map((element, index) => (
          <Box
            style={{
              padding: "12px",
              backgroundColor: "#FFFFFF",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#375280",
                }}
              >
                {" "}
                {element.attributes.discount}{this.transLoyaltyLng("% off on")}{" "}
                {element.attributes.description}
              </Typography>
              <Box style={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Typography
                  style={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#94A3B8",
                  }}
                >
                  {this.transLoyaltyLng("Claim offer using")}
                </Typography>
                <Box style={{ width: "18px", height: "18px" }}>
                  <img src={badge} style={{ width: "100%", height: "100%" }} />
                </Box>
                <Typography
                  style={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#94A3B8",
                  }}
                >
                  {element.attributes.loyalty_points} {this.transLoyaltyLng("points")}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                width: "130px",
                height: "35px",
                backgroundColor: element.attributes.is_redeem
                  ? "#CBD5E1"
                  : "#CCBEB1",
                padding: "10px 16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              data-test-id={`redeemOpen-${index}`}
              onClick={() =>
                element.attributes.is_redeem
                  ? undefined
                  : this.handleRedeem(+element.id)
              }
            >
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontWeight: 900,
                  color: "#FFFFFF",
                }}
              >
                {this.transLoyaltyLng("Redeem")}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  NavigationHeader = () => (
    <div
      style={webStyle.innerContainer11}>
      <Typography style={webStyle.innerContainer12} onClick={this.openLandingPage}>
        Home
      </Typography>
      <img src={Vector} />
      <Typography style={webStyle.innerContainer23}>
        Loyalty Points
      </Typography>
    </div>
  );

  DrawerToggle = () => (
    <div style={{ marginLeft: window.innerWidth >= 768 ? "66%" : "42%", marginTop: window.innerWidth >= 768 ? "5%" : "7%" }}>
      <MoreVertIcon
        data-test-id="drawerOpenMoreVertIcon"
        onClick={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
      />
    </div>
  );

  DrawerContent = () => (
    <BuyerDrawer
      navigation={this.props.navigation}
      data-test-id="drawerClose"
      open={this.state.isDrawerOpen}
      onClose={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
    >
      <div>
        <div >
          <Typography style={webStyle.innerContainer3}>
            <img src={scroll} />
            {this.state.fullName}
          </Typography>
        </div>
        <Typography style={webStyle.innerContainer4}>
          Welcome to your Account
        </Typography>
        <Sidebar navigation={this.props.navigation} />
      </div>
      <Sidebar navigation={this.props.navigation} data-test-id="mobilesidebar" />
    </BuyerDrawer>
  );
  commonItem = () => {
    return (
      <>
        {this.state.isLoading ? (
          <Loader
            id="loyalty-main-loader"
            dataTestId="loyalty-main-loader-test"
          />
        ) : (
          <>
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography style={webStyle.headerStyle}>
                  Loyalty Points
                </Typography>
                <Box style={{ height: "32px", width: "32px" }}>
                  <img
                    src={loyality}
                    style={{ height: "100%", width: "100%" }}
                  />
                </Box>
              </Box>
              <Box bgcolor="white" style={webStyle.boxStyle}>
                <Box style={webStyle.alignStart}>
                  <Box style={{ display: "flex", gap: 20 }}>
                    <Box style={{ height: "64px", width: "64px" }}>
                      <img
                        src={badge}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                    <Box>
                      <Typography style={webStyle.boxNumber}>
                        {this.state.buyerLoyalty.point_balance} Points
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "#375280",
                          fontFamily: "Lato",
                        }}
                      >
                        {this.state.buyerLoyalty.message}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: "-52px" }}>
                  <ShoppingBtn
                    data-test-id="continueShoppingPage"
                    onClick={this.handleLoyalty}
                  >
                    {!this.state.loyaltyPage ? "Use Loyalty Points" : "Back"}
                  </ShoppingBtn>
                </Box>
              </Box>
            </Box>
            {!this.state.loyaltyPage && this.renderTransactions()}
            {this.state.loyaltyPage && this.renderRedeemPoints()}
            <DeleteDialogSmall
              open={this.state.redeemDialogOpen}
              data-test-id="singleDeletePop"
              onClose={this.handleRedeemDialogClose}
            >
              <DiologcontentStyled2>
                <Box
                  style={{
                    padding: "25px 25px 0px 25px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={this.handleRedeemDialogClose}
                  >
                    <img src={close} alt="close" />
                  </Box>
                  <Box style={webStyle.headingContainer}>
                    <Typography style={webStyle.primaryText}>
                      Redeem points?
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontWeight: 400,
                        textAlign: "center",
                        color: "#375280",
                      }}
                    >
                      Tap ‘Yes’ to collect the reward using points.{" "}
                    </Typography>
                  </Box>
                  <Box></Box>
                </Box>
              </DiologcontentStyled2>
              <DialogActions
                style={{
                  justifyContent: "center",
                  padding: "0px 40px 40px 40px ",
                }}
              >
                <Button
                  data-test-id="handleConfirmAdd"
                  style={webStyle.confirmButton}
                  onClick={this.handleRedeemDialogClose}
                >
                  Cancel
                </Button>
                <Button
                  style={webStyle.cancelButton}
                  data-test-id="handleYesClick"
                  onClick={this.handleYesClick}
                >
                  Yes
                </Button>
              </DialogActions>
            </DeleteDialogSmall>

            <CongratulationsDialog
              open={this.state.congratulationDialog}
              data-test-id="congratulation"
              onClose={this.handleCongratulationClose}
            >
              <DiologcontentStyled2>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <Box style={{ width: "200.59px", height: "125.4px" }}>
                    <img
                      src={congratulations}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "30px",
                      fontWeight: 700,
                      color: "#375280",
                    }}
                  >
                    Congratulations
                  </Typography>

                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "24px",
                      fontWeight: 400,
                      color: "#375280",
                    }}
                  >
                    Thank you! You will get a free delivery on your next
                    order.
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      height: "64px",
                      width: "302px",
                      backgroundColor: "#CCBEB1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato",
                        fontSize: "18px",
                        fontWeight: 800,
                        color: "#FFFFFF",
                      }}
                    >
                      Track Return
                    </Typography>
                  </Box>
                </Box>
              </DiologcontentStyled2>
            </CongratulationsDialog>
          </>
        )}
      </>

    )
  }

  MobileView = () => {
    if (window.innerWidth >= 951) return null;
    return (
      <>
        <Grid style={{ display: "flex", width: "90vw" }}>
          {this.NavigationHeader()}
          {this.DrawerToggle()}
          {this.DrawerContent()}
        </Grid>
        <Grid
          item
          xs={11}
          sm={11}
          style={{
            marginTop: "87px",
            paddingInline: 20,
            justifyContent: "flex-end",
          }}
        >
          {this.commonItem()}
        </Grid>
      </>
    )
  }
  DesktopView = () => {
    if (window.innerWidth < 951) return null;
    return (
      <>
        <Grid item xs={3} sm={3}>
          <div
            style={webStyle.innerContainer11}>
            <Typography style={webStyle.innerContainer12} onClick={this.openLandingPage}>
              Home
            </Typography>
            <img src={Vector} />
            <Typography style={webStyle.innerContainer23}>
              Loyalty Points
            </Typography>
          </div>
          <div>
            <div >
              <Typography style={webStyle.innerContainer3}>
                <img src={scroll} />
                {this.state.fullName}
              </Typography>
            </div>
            <Typography style={webStyle.innerContainer4}>
              Welcome to your Account
            </Typography>
            <Sidebar navigation={this.props.navigation} />
          </div>
        </Grid>
        <Grid
          item
          xs={8}
          sm={8}
          style={{
            marginTop: "87px",
            paddingInline: 20,
            justifyContent: "flex-end",
          }}
        >
          {this.commonItem()}
        </Grid>
      </>
    )
  }
  // Customizable Area End

  render() {
    const {
      isAlert,
      message,
    } = this.state;
    return (
      // Customizable Area Start
      <>
        {this.state.buyerstylish ? <StylishBuyerHeader navigation={this.props.navigation} /> : <Header navigation={this.props.navigation} />}
        <Grid style={{ backgroundColor: "rgb(248, 248, 248)" }} container>
          {this.MobileView()}
          {this.DesktopView()}
        </Grid>
        <ReusableSnackbar
          onClose={this.onCloseAlert}
          open={isAlert}
          severity={"error"}
          message={message}
          dataTestId="alertTestId"
          autoHideDuration={3000}
          dir={i18n.dir()}
        />
        <Footer navigation={this.props.navigation} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DeleteDialogSmall = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
    minWidth: '620px',
    minHeight: "260px"
  },
  '& .MuiPaper-rounded': {
    borderRadius: '1px',
    border: "1px solid #CCBEB1"
  },
  "& .MuiDialogContent-root:first-child": {
    paddingTop: "0px"
  }
});

const CongratulationsDialog = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
    minWidth: '529px',
    minHeight: "440px"
  },
  '& .MuiPaper-rounded': {
    borderRadius: '1px',
    border: "1px solid #CCBEB1"
  },
  "& .MuiDialogContent-root:first-child": {
    paddingTop: "52px"
  }
});

const NoTransactionTypography = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  marginTop: 120,
  fontFamily: "Lato, sans-serif",
  fontWeight: 500,
  fontSize: 20,
  color: "#375280",
});

const DiologcontentStyled2 = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: "24px",
  flex: "none",
  height: "fit-content"
});
const ShoppingBtn = styled(Button)({
  textTransform: "capitalize",
  backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
  gap: "8px",
  margin: "10px 0",
  width: "100% !important",
  borderRadius: "3px",
  height: "56px !important",
  "& .MuiButton-label": {
    fontWeight: 800,
    fontSize: "20px",
    fontFamily: "Lato, sans-serif",
    color: "white",
    width: '307px'
  }
})
const webStyle = {
  addAddressContainer: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    width: "96%",
    lineHeight: '26px',
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
  },
  boxStyle:{display: "flex",width:"96%",alignItems: "center",height: "188px",borderRadius: "4px",margin: "30px 0px",border: "1px solid #E2E8F0",padding: "8px 20px",justifyContent: "space-between",
    flexDirection: window.innerWidth > 950 ? "row" : "column",
    
  } as  React.CSSProperties,
  headerStyle: { fontWeight: 800, fontSize: "20px", color: "#375280", fontFamily: "Lato" },
  dropdown: {
    borderRadius: '4px',
    boxShadow: '0px 2px 8px 0px #00000014',
    backgroundColor: 'white',
    zIndex: 1,
    left: 0,
    width: '120px',
    top: '100%',
    position: 'absolute',
  },
  primaryText: {
    fontSize: '30px',
    color: "#375280",
    fontWeight: 500,
    fontFamily: "Lato"
  },
  headingContainer: {
    marginLeft: "12px", display: "flex", flexDirection: "column",
    justifyContent: "center",
    alignItems: "center", gap: "24px"
  } as React.CSSProperties,
  boxNumber: { fontWeight: 800, fontSize: "40px", color: "#CCBEB1", fontFamily: "Lato", marginBottom: '10px' },
  confirmButton: {
    alignItems: "center",
    fontSize: "20px",
    justifyContent: "center",
    fontWeight: 500,
    fontFamily: "Lato",
    display: "flex",
    color: "#375280",
    backgroundColor: "#FFFFFF",
    border: "1px solid #CCBEB1",
    height: "56px",
    borderRadius: "5px",
    width: "252px",
    padding: "10px",
    cursor: "pointer",
    textTransform: "capitalize"
  } as React.CSSProperties,
  dropdownP: {
    fontWeight: "600",
    fontSize: "12px",
    color: "#375280",
    paddingRight: '6px',
    paddingLeft: '6px',
    lineHeight: "22px",
    paddingBottom: '10px',
    borderBottom: '3px solid #D5D5D5',
  },
  dropdownP1: {
    fontSize: "12px",
    color: "#DC2626",
    fontWeight: "600",
    paddingRight: '6px',
    lineHeight: "22px",
    paddingLeft: '6px',
  },
  cancelButton: {
    alignItems: "center",
    fontFamily: "Lato",
    fontSize: "20px",
    justifyContent: "center",
    fontWeight: 800,
    display: "flex",
    color: "#FFFFFF",
    backgroundColor: "#CCBEB1",
    height: "56px",
    borderRadius: "5px",
    width: "252px",
    padding: "10px",
    cursor: "pointer",
    textTransform: "capitalize"
  } as React.CSSProperties,
  alignStart: { display: "flex", flexDirection: "column" as 'column', justifyContent: "space-between", alignItems: "start" },
  innerContainer11: {
    gap: "10px",
    display: "flex",
    fontWeight: 400,
    alignItems: "center",
    lineHeight: "22px",
    marginLeft: "40px",
    marginTop: "27px",
    color: "#475569",
    cursor: "pointer"
  },
  innerContainer12: {
    lineHeight: "22px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px",
    cursor: "pointer"
  },
  innerContainer23: {
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#475569"
  },
  innerContainer3: {
    alignItems: "center",
    display: "flex",
    marginTop: "31px",
    lineHeight: "33.5px",
    marginLeft: "40px",
    color: "#375280",
    fontWeight: 800,
    gap: "15px",
    fontStyle: "normal",
    fontSize: "28px",
    fontFamily: "Lato , sans-serif",
  },
  innerContainer4: {
    marginBottom: "37px",
    marginLeft: "40px",
    color: "#807D7E",
    fontWeight: 400,
    lineHeight: "33.5px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",

  },
};

// Customizable Area End
