import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { removeStorageData, setStorageData, getStorageData } from "../../../framework/src/Utilities";
import i18n, { i18nUpdate } from "../../../components/src/i18next/i18n"
import storage from "../../../framework/src/StorageProvider.web";
import { apiCall } from "../../../components/src/APICall";

interface APIPayloadType {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: object;
    token?: string;
    type?: string;
}

interface HeaderTextLResp {
    header_text_ar: string,
    header_text_eng: string,
 }

interface Account {
    id: number;
    first_name: string | null;
    last_name: string | null;
    full_phone_number: string;
    country_code: number;
    phone_number: number;
    email: string;
    activated: boolean;
    device_id: string | null;
    unique_auth_id: string;
    password_digest: string;
    created_at: string;
    updated_at: string;
    user_name: string | null;
    platform: string | null;
    user_type: string | null;
    app_language_id: number | null;
    last_visit_at: string | null;
    is_blacklisted: boolean;
    suspend_until: string | null;
    status: string;
    gender: string | null;
    date_of_birth: string | null;
    age: number | null;
    stripe_id: string | null;
    stripe_subscription_id: string | null;
    stripe_subscription_date: string | null;
    role: string;
    full_name: string;
    is_verified: boolean | null;
    share_token: string | null;
    approve_status: string;
    seller_status: string;
    notification: {};
    customer_id: string | null;
    language: string;
    currency: number;
    stylist_redirect_flag?: string;
}

interface Meta {
    token: string;
    refresh_token: string;
    id: number;
    account: Account;
    sms_otp_token: string;
}

interface PostLoginResponse {
    meta: Meta;
    data: {
        activated: string;
    }
}

interface ValidResponseType {
    message?: object;
    data: object;
    meta: object;
}

interface InvalidResponseType {
    errors: Array<string>;
}

type AlertType = 'success' | 'error' | 'warning' | 'info';

enum Language {
    English = 0,
    Arabic = 1
}

enum Currency {
    Dollar = 0,
    Dinar = 1
}
interface MockForGoogleLoginObject {
    sub: string;
    email: string;
    name: string;
}

interface SocialAccountAttributes {
    provider: string;
    email: string;
    unique_auth_id: string;
    role: number;
    first_name: string;
    last_name: string;
    language: Language;
    currency: Currency;
    device_id: string;
}

interface SocialAccountRequestBody {
    data: {
        type: 'social_account';
        attributes: SocialAccountAttributes;
    }
}
interface ErrorDetails {
    failed_login: string;
    is_return: boolean;
    activated: boolean;
    account_id: number;
    full_phone_number: string;
}

interface AddNumberError {
    message: string;
}

interface AddNumberErrorObject {
    errors: AddNumberError;
}




export interface CountrySuccess {
    numeric_code: string;
    country_full_name: string;
    country_code: string;
    country_flag: string;
}

interface ResendSMSOTP {
    data: {
        type: string;
        attributes: {
            full_phone_number: string;
        }
    }
}

interface ErrorResponse {
    errors: {
        pin: string;
    };
}

interface SuccessResponse {
    message: string;
}

type VerifySMSOTPResponse = ErrorResponse | SuccessResponse;

interface ResendOTPResponse {
    meta: {
        token: string;
    };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isEmail: string;
    showPasswordValue: boolean;
    passwords: string;
    mobileNumber: string;
    mobileError: boolean;
    errorsMessage: {
        emailMessage?: string;
        passwordMessage?: string;
        errorEmail?: boolean;
        errorPassword?: boolean;
        errorMsgMobile?: string;
        mobileNumberError?: boolean;
    };
    countrySuccess: Array<CountrySuccess>;
    isAlert: boolean;
    alertMsg: string;
    alertType: AlertType;
    alertTyperoute: AlertType;
    emailError: boolean;
    passError: boolean;
    login_page: boolean;
    userType: string;
    emailAndOtpVerify: boolean;
    otpInput: string;
    errorOtpMsg: string;
    selectedCountry: CountrySuccess | null;
    dropdownOpen: boolean;
    isCountryData: string;
    buyerStylish: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    emailLoginFormApiCallId: string = "";
    googleLoginApiCallId: string = "";
    countryCodeAPICallId: string = "";
    addPhoneNumberApiCallId: string = "";
    resendOTPCallID: string = "";
    verifySMSOTPCallID: string = "";
    getHeaderCallId: string = "";


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isEmail: "",
            showPasswordValue: false,
            passwords: "",
            mobileNumber: "",
            mobileError: false,
            errorsMessage: {
                emailMessage: "",
                passwordMessage: "",
                errorEmail: false,
                errorPassword: false,
                errorMsgMobile: "",
                mobileNumberError: false
            },
            countrySuccess: [{
                numeric_code: "",
                country_full_name: "",
                country_code: "",
                country_flag: ""
            }],
            isAlert: false,
            login_page: false,
            alertMsg: '',
            alertType: 'success' as AlertType,
            alertTyperoute: 'error' as AlertType,
            emailError: false,
            passError: false,
            userType: "",
            emailAndOtpVerify: false,
            otpInput: '',
            errorOtpMsg: "",
            selectedCountry: null,
            dropdownOpen: false,
            isCountryData: "+965",
            buyerStylish: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        i18nUpdate()
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson) {
                this.googleLoginApiResponce(apiRequestCallId, responseJson);
                this.addPhoneNumberResponse(apiRequestCallId, responseJson);
                this.handleVerifySMSOTPResponse(apiRequestCallId, responseJson);
            }
            if (responseJson && !responseJson.errors) {
                this.handleResendOTPResponse(apiRequestCallId, responseJson);
                this.apiSucessCall(apiRequestCallId, responseJson);

            } else if (responseJson && responseJson.errors) {
                this.apiFailureCall(responseJson.errors)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start


    transLogin = (klog: string) => {
        return i18n.t(klog, { ns: "loginSignup" })
    }

    getLHeaderText = async () => {
    this.getHeaderCallId = await apiCall({
        token: undefined,
        method: configJSON.validationApiMethodType,
        contentType: configJSON.validationApiContentType,
        endPoint: configJSON.headerTextEndPoint,
    })
    }
    
    handleLResponseHeaderText = (responseL: HeaderTextLResp) => {
        const isLRtl = i18n.dir() === "rtl"
        let headerL = ""
        if(responseL?.header_text_eng) {
        headerL = responseL.header_text_eng
        } else if(responseL?.header_text_ar && isLRtl){
        headerL = responseL.header_text_ar
        } 
        setStorageData("headerText", headerL)
    }

    handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isEmail = event.target.value
        this.setState({
            isEmail: isEmail,
            emailError: false,
            passError: false,
            errorsMessage: {
                emailMessage: "",
                errorEmail: false
            }
        })
    };
    handleLogin = (result: string) => {
        if (result == 'true') {

            setTimeout(() => {
                this.setState((state, props) => ({
                    login_page: true
                }), () => {
                    setTimeout(() => {
                        this.setState(() => ({

                            login_page: false
                        }))
                    }, 1500);
                })
            }, 100);
        }

    }
    async componentDidMount() {
        super.componentDidMount();
        this.getLHeaderText()
        this.getCountryCodeApi();
        const userRole = await getStorageData('userRole');
        const buyerStylish = await getStorageData('exploreBuyer', true);
        if(buyerStylish) this.setState({buyerStylish})
        const parseData =JSON.parse(userRole)
        this.setState({
            userType: parseData?.userType
        })
        let result = await storage.get('login_page');
        console.log("hello=>", userRole, buyerStylish, result)
        this.handleLogin(result)
    }

    handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const passwords = event.target.value;
        const passRegx = configJSON.passRegx;
       
            this.setState({
                passError: false,
                passwords: passwords,
                errorsMessage: {
                    passwordMessage: "",
                    errorPassword: false
                }
            });
    }

    handleTogglePassword = () => {
        this.setState((prevState) => ({
            showPasswordValue: !prevState.showPasswordValue,
        }));
    };

    handleSumitlogin = (submitEvent: React.FormEvent) => {

        submitEvent.preventDefault();

        const { isEmail, passwords } = this.state;
        const emailRegex = configJSON.emailRegex;
        const passRegx = configJSON.passRegx;
        if (isEmail === "" || !emailRegex.test(isEmail)) {
            if (isEmail !== "") {
                this.setState({
                    emailError: true,
                    errorsMessage: {
                        emailMessage: this.transLogin(configJSON.emailValidMsg),
                        errorEmail: true
                    }
                });
            } else {
                this.setState({
                    emailError: true,
                    errorsMessage: {
                        emailMessage: this.transLogin(configJSON.emailValidationMsg),
                        errorEmail: true
                    }
                });
            }
        } else if (passwords === "") {
                this.setState({
                    passError: true,
                    emailError: false,
                    errorsMessage: {
                        passwordMessage: this.transLogin(configJSON.validationPassMsg),
                        errorPassword: true
                    }
                })
        }
        else {
            this.postFormApi()
        }
        removeStorageData('full_name')
        removeStorageData('email')
        removeStorageData('account_holder_name')
        removeStorageData('iban')
        removeStorageData('owner_full_address')
        removeStorageData('owner_contact_number')
        removeStorageData('isCountryData')
        removeStorageData('company_full_address')
        removeStorageData('isCountryData1')
        removeStorageData('company_contact_number')
        removeStorageData('account_number')
        removeStorageData('social_media_account')
        removeStorageData('exploreBuyer')
    }

    apiCallService = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,

        };
        const requestMessageSerice = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessageSerice.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessageSerice.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        requestMessageSerice.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessageSerice.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessageSerice.id, requestMessageSerice);
        return requestMessageSerice.messageId;
    };
    getNavigationActivateaccount = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'AccountActiveProfile'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);

    }

    getnavigationToASplashScreen = () => {
        const navigateIcon: Message = new Message(getName(MessageEnum.NavigationMessage))
        navigateIcon.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'Home'
        );
        navigateIcon.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
        navigateIcon.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(navigateIcon);
    }
    getNavigationSellerDashboardaccount = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'SellerStore'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);

    }

    getNavigationCreateStore = () => {


        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'CreateSellerStore'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);

    }

    apiSucessCall = async (apiRequestCallId: string, responseJson: CountrySuccess[] & PostLoginResponse & HeaderTextLResp) => {
        if (apiRequestCallId === this.countryCodeAPICallId) {
            this.setState({
                countrySuccess: responseJson,
            });
        }

        if (apiRequestCallId === this.emailLoginFormApiCallId) {

            if (responseJson && responseJson.meta && responseJson?.meta?.token) {
                this.handlEmailLoginResp(responseJson)
            }
        }

        if(apiRequestCallId === this.getHeaderCallId){
            this.handleLResponseHeaderText(responseJson)
        }
    };

    handleDifferentRoleMsg = () => {
        this.setState({
            isAlert: true,
            alertMsg: this.transLogin("You have selected a different role to sign in. Please select role again."),
            alertType: "warning"
        })
    }

    handleRoleVarType = () => {
        const isRoleInWord = configJSON.roleInWordOptipons.includes(this.state.userType)
        const userRoleInNumber = isRoleInWord ? configJSON.typeOptions.find((element: {[key:string]:string}) => element.label === this.state.userType)?.value : this.state.userType
        return userRoleInNumber
    }

    handlEmailLoginResp = async (responseJson: PostLoginResponse) => {
        const userRoleInNumber = this.handleRoleVarType()
        const filter = configJSON.typeOptions.find((element: {[key:string]:string}) => element.value === userRoleInNumber);
        if ((filter?.label === responseJson.meta.account?.role || (this.state.userType == null && responseJson.meta.account?.role === "buyer"))) {
            setStorageData("auth-token", responseJson.meta.token);
            setStorageData("user_id", responseJson.meta.id)
            setStorageData("user_data", JSON.stringify(responseJson.meta.account))
            setStorageData("isLogin", "false")
            const tempRole = {
                userType: responseJson.meta?.account?.role,
                language: responseJson.meta?.account?.language,
                currency: responseJson.meta?.account?.currency
            };
            
            const language = tempRole.language === "Arabic" ? "ar" : "en";

            setStorageData("lang",language)
            await i18nUpdate(language);
            await setStorageData("userRole", JSON.stringify(tempRole));
            this.setState({ isAlert: true, alertMsg: this.transLogin(configJSON.Login_SuccessfullyMsg), alertType: configJSON.SuccessMsg, isEmail: '', passwords: '' });
            const storedPath = await getStorageData('storedPath')
            switch (tempRole.userType) {
                case configJSON.buyerMsg:
                    if(storedPath) {
                        document.location.href = storedPath;
                        removeStorageData('storedPath')
                        break;
                    }                         
                    this.handleNavigations('LandingPage')
                    break;
                case configJSON.sellerMsg:
                    await this.handleSellerMsg(responseJson.meta.account.seller_status, responseJson.meta.account.approve_status)
                    break;
                case configJSON.StylishMsg:
                    this.handlStylistNavigation(responseJson.meta.account?.stylist_redirect_flag)
                    break;
            }
            return
        } 
        this.handleDifferentRoleMsg()
    }


    openMobileScreen = () => {
        const toMobile = new Message(getName(MessageEnum.NavigationMessage));
        toMobile.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.MobileAccountLoginBlockMsg);
        toMobile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toMobile);
    };

    opeForgotPasswordScreen = () => {
        const toMobile = new Message(getName(MessageEnum.NavigationMessage));
        toMobile.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.ForgotPasswordMsg);
        toMobile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toMobile);
    };

    getNavigationStylist = (navigateTo: string) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage), `${navigateTo}`
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {

        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    };
    handlStylistNavigation = (stylist_status?: string) => {
        if (stylist_status == 'stylist_document_submission_page') {
            this.getNavigationStylist('StylistUploadDocumentsweb')
        } else if (stylist_status == 'stylist_landing_page') {
            setStorageData("stylishType", "landing");
            this.getNavigationStylist('StylishDashboard');
        } else if (stylist_status == "stylist_confirmation_page") {
            setStorageData("stylishType", "confirmation");
            this.getNavigationStylist("StylishDashboard");
        } else {
            this.getNavigationStylist("StylistAccountActiveProfile")
        }
    };
    handleSellerMsg = async (seller_status: string, approve_status: string) => {
        await setStorageData("seller-account-step", JSON.stringify({approve_status: approve_status,
            seller_status: seller_status}));
        if (seller_status === 'Signup') {
            this.getNavigationActivateaccount()
        } else if (seller_status === 'Account_activation') {
            this.getNavigationCreateStore()
        } else {
            this.getNavigationSellerDashboardaccount()
        }
    }
    apiFailureCall = async (responseError: ErrorDetails[]) => {
        if (responseError[0].activated === false && (responseError[0].full_phone_number === '' || responseError[0].full_phone_number === null)) {
            await setStorageData("account_id", responseError[0].account_id)
            this.handleNavigations('UserAccountActivation')

        }
        else if (responseError[0].activated === false && (responseError[0].full_phone_number !== '' || responseError[0].full_phone_number !== null)) {
            await setStorageData('resend_otp_number', responseError[0].full_phone_number)
            const body = {
                "data": {
                    type: "sms_account",
                    attributes: {
                        full_phone_number: responseError[0].full_phone_number
                    }
                }
            }

            this.handleResendSMSOTP(body);
        }
        else {
            this.setState({
                isAlert: true,
                alertMsg: responseError[0].failed_login,
                alertType: configJSON.errorMsg
            });
        }
    };

    postFormApi = async () => {
        let fcmToken = await getStorageData('fcm_token') || ""
        const body = {
            data: {
                type: configJSON.email_accountMsg,
                attributes: {
                    email: this.state.isEmail,
                    password: this.state.passwords,
                    device_id: fcmToken
                }
            }
        }
        this.emailLoginFormApiCallId = await this.apiCallService({
            contentType: configJSON.loginApiContentType,
            method: configJSON.loginAPiMethod,
            endPoint: configJSON.loginEndPoint,
            body: body
        });
    };

    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };
    openRegistrationScreen = () => {
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.EmailAccountRegistrationMsg);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    };

    handleNavigations = (name: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), name);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

    googleLoginApi = async (tokenResponse: MockForGoogleLoginObject) => {
        const language_currency = await getStorageData(('userRole'));
        const parsedData = JSON.parse(language_currency);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.googleLoginApiCallId = requestMessage.messageId;
        const requestBody: SocialAccountRequestBody = {
            data: {
                type: "social_account",
                attributes: {
                    provider: "google",
                    email: tokenResponse?.email,
                    "unique_auth_id": tokenResponse?.sub,
                    role: Number(parsedData?.userType),
                    first_name: tokenResponse?.name.split(' ')[0],
                    last_name: tokenResponse?.name.split(' ').slice(1).join(' '),
                    language: Number(parsedData?.languageValue),
                    currency: Number(parsedData?.currency),
                    device_id: ""
                }
            }
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.googleLoginApiEndPoint
        );
        const header = {
            "Content-Type": configJSON.loginApiContentType
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    googleLoginApiResponce = async (apiRequestCallId: string, responseJson: PostLoginResponse & AddNumberErrorObject) => {

        if (apiRequestCallId === this.googleLoginApiCallId) {
            if (responseJson && responseJson.meta && responseJson?.meta?.token) {
                this.handleActivatedUsersData(responseJson)
              
            }
        }
    }

handleActivatedUsersData=async(responseJson: PostLoginResponse & AddNumberErrorObject)=>{
    if(responseJson?.data?.activated || responseJson?.meta?.account?.activated){
        const filter = configJSON.typeOptions.find((element: any) => element.value === this.state.userType);
      
        if ((filter?.label === responseJson.meta.account?.role ||  responseJson.meta.account?.role === "buyer")) {
            setStorageData("user_id", responseJson.meta.id)
            setStorageData("isLogin", "false")
            setStorageData("user_data", JSON.stringify(responseJson.meta.account))
            setStorageData("auth-token", responseJson.meta.token);
            const userRoles = await getStorageData(('userRole'));
            const parsedData = JSON.parse(userRoles);
            const tempRole = {
                language: responseJson.meta?.account?.language,
                currency: responseJson.meta?.account?.currency,
                userType: responseJson.meta?.account?.role,
            };
            const tempRoleS = {
                language:  Number(parsedData?.languageValue),
                currency: Number(parsedData?.currency),
                userType:  parsedData?.userType,
            };
            const storedPath2 = await getStorageData('storedPath')
            this.setState({ isAlert: true, alertMsg: configJSON.Login_SuccessfullyMsg , alertType: configJSON.SuccessMsg });
            await setStorageData("userRole",  JSON.stringify(tempRoleS) );
            switch (tempRole.userType) {
                case configJSON.sellerMsg:
                    this.handleSellerMsg(responseJson.meta.account.seller_status, responseJson.meta.account.approve_status)
                    break;
                case configJSON.StylishMsg:
                    this.handlStylistNavigation(responseJson.meta.account?.stylist_redirect_flag)
                    break;
                case configJSON.buyerMsg:
                    if(storedPath2) {
                        document.location.href = storedPath2;
                        removeStorageData('storedPath')
                        break;
                    } 
                    this.handleNavigations('LandingPage');
                    break;
            }
        } 
        else {
            this.setState({
                alertMsg: this.transLogin("You have selected a different role to sign in. Please select role again."),
                alertType: "warning",
                isAlert: true,
            })
        }
    }
    else{
        this.setState({ isAlert: true, alertMsg: configJSON.register_SuccessfullyMsg, alertType: configJSON.SuccessMsg, });
    }
}

    addPhoneNumberResponse = async (apiRequestCallId: string, responseJson: PostLoginResponse & AddNumberErrorObject) => {
        if (apiRequestCallId === this.addPhoneNumberApiCallId) {
            if (responseJson.errors) {
                this.setState({
                    isAlert: true,
                    alertMsg: responseJson.errors.message,
                    alertType: 'error'
                });
            }
            else {
                this.handleNavigations('EmailAccountLoginBlock');
            }
        }
    }

    handleEmailAndOtpVerifyClose = () => {
        this.setState({ emailAndOtpVerify: false, otpInput: "", errorOtpMsg: "" });
    };

    getCountryCodeApi = async () => {
        this.countryCodeAPICallId = await this.apiCallCommon({
            contentType: configJSON.countryApiContentType,
            method: configJSON.httpMethodType,
            endPoint: configJSON.countryCodeEndPoint
        });
    };
    apiCallCommon = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
        };
        const mobileRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        mobileRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        mobileRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        body &&
            mobileRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );

        mobileRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(mobileRequestMessage.id, mobileRequestMessage);
        return mobileRequestMessage.messageId;
    };

    handleAccountChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const selectedCode = event.target.value as string;
        const selectedCountry = this.state.countrySuccess.find(
          (country) => country.numeric_code === selectedCode
        );
    
        this.setState({ selectedCountry: selectedCountry || null,  isCountryData:selectedCode});
      };

    handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const mobileNumber = event.target.value;
        const mobileNumberRegx = configJSON.neightDigRegex;
        if (mobileNumberRegx.test(mobileNumber) && mobileNumber.length <= 7) {
            this.setState({
                mobileNumber: mobileNumber,
                mobileError: true,
                errorsMessage: {
                    mobileNumberError: true,
                    errorMsgMobile: ""
                }
            });
        } else {
            this.setState({
                mobileNumber: mobileNumber,
                mobileError: false,
                errorsMessage: {
                    mobileNumberError: false,
                    errorMsgMobile: this.transLogin(configJSON.alphnewmertic)
                }
            });
        }
    };


    handleSubmit = (submitEvent: React.FormEvent) => {
        submitEvent.preventDefault();
        const { mobileNumber } = this.state;
        const isNumeric = /^\d+$/;
        if (mobileNumber.trim() === "") {
            this.setState({
                mobileError: true,
                errorsMessage: {
                    errorMsgMobile: this.transLogin(configJSON.phoneValidationMsg),
                    mobileNumberError: true
                }
            });
        } else if (!isNumeric.test(mobileNumber) || mobileNumber.length <= 7) {
            this.setState({
                mobileError: true,
                errorsMessage: {
                    errorMsgMobile: this.transLogin(configJSON.alphnewmertic),
                    mobileNumberError: true
                }
            });
        } else {
            this.setState({
                mobileError: false,
                errorsMessage: {
                    errorMsgMobile: "",
                    mobileNumberError: false
                }
            });
            this.addPhoneNumberApi();
        }
    };

    addPhoneNumberApi = async () => {
        const accoundId = await getStorageData('account_id')
        const formData = new FormData();
        formData.append('account_id', accoundId);
        formData.append('full_phone_number', this.state.isCountryData + this.state.mobileNumber);

        this.addPhoneNumberApiCallId = await this.apiCallCommon({
            method: configJSON.postApimethod,
            endPoint: configJSON.AddPhoneNumberEndPoint,
            body: formData
        });
    };

    handleOTPChange = (otps: string) => {
        this.setState({
            otpInput: otps,
            errorOtpMsg: "",
        });
    }

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace' || e.key === 'Delete' || (e.key >= '0' && e.key <= '9')) {
            return;
        }
        e.preventDefault();
    };

    handleSubmitOtp = async () => {
        const { otpInput } = this.state;
        const errorMsg = otpInput === '' || otpInput.length !== 4 ? this.transLogin(configJSON.enterOTP) : '';
        this.setState({ errorOtpMsg: errorMsg });
        const tokenMobile = await getStorageData("sms_otp_token");
        const otpTypeSocial = configJSON.socialType ;

        if (errorMsg === "") {
            const apiData = {
                token: tokenMobile,
                pin: otpInput,
                otp_type: otpTypeSocial
            };

            const header = {
                "Content-Type": "application/json"
            };



            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
            this.verifySMSOTPCallID = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.postPhoneVarificationEndPoint)


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(apiData)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                'POST'
            );

            runEngine.sendMessage(requestMessage.id, requestMessage)
        }
    };

    handleResendSMSOTP = async (body?: ResendSMSOTP) => {
        const phone_number = await getStorageData('resend_otp_number');

        const defaultData = {
            "data": {
                "type": "sms_account",
                "attributes": {
                    "full_phone_number": phone_number
                }
            }
        }
        const header = {
            "Content-Type": "application/json"
        };

        const data = body || defaultData

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.resendOTPCallID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postResendOTPEndPoint)


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'POST'
        );

        runEngine.sendMessage(requestMessage.id, requestMessage)
    };

    maskPhone = () => {
        let number = localStorage.getItem('resend_otp_number');
        let phoneNumber = number;
        let length = phoneNumber?.length;
        let maskedPart1 = length && phoneNumber?.substring(length - 2);
        let starPhone = `*******${maskedPart1}`;
        return starPhone;
    };

    handleVerifySMSOTPResponse(
        apiRequestCallId: string,
        responseJson: VerifySMSOTPResponse
    ) {
        if (apiRequestCallId === this.verifySMSOTPCallID) {
            if ('errors' in responseJson) {
                this.setState({
                    isAlert: true,
                    alertMsg: responseJson.errors.pin,
                    alertType: configJSON.errorMsg
                });
            } else {
                this.setState({
                    emailAndOtpVerify: false,
                    otpInput: ''
                });
                this.setState({
                    isAlert: true,
                    alertMsg: responseJson.message,
                    alertType: configJSON.SuccessMsg
                });
            }
        }
    };

    handleResendOTPResponse = async (
        apiRequestCallId: string,
        responseJson: ResendOTPResponse
    ) => {
        if (apiRequestCallId === this.resendOTPCallID) {
            this.setState({ emailAndOtpVerify: true });

            await setStorageData("sms_otp_token", responseJson.meta.token);
        }
    };


    // Customizable Area End
}
